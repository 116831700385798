import { useEffect } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useAtomValue } from 'jotai'
import {
  TransferList,
  Content,
  Spacing,
  Steps,
  TextDecoration,
  PageChangePrompt,
  Panel,
  PageHeader,
  Spinner,
} from 'presentation/components'
import { Button } from '@/presentation/components/atoms'
import { ButtonWrapper, Operator, StepsWrapper } from './styles'

import { TEMP_OPERATOR_ID } from '@/utils/constants'
import { orderIdAtom } from 'main/store'
import api from '@/infra/api'
import { useDispatch } from 'react-redux'
import { useOrder } from './hooks/useOrder'
import { useNavigate, useLocation } from 'react-router-dom'
import { ChangeIcon } from './assets/ChangeIcon'
import { Link } from 'react-router-dom'

const RechargeEmployeesSelection = () => {
  const navigate = useNavigate()
  const orderIdToEdit = useAtomValue(orderIdAtom)
  const location = useLocation()

  const operatorCardId = location.state?.operatorCardId
  const operatorCardName = location.state?.operatorCardName
  const operatorName = location.state?.operatorName
  const isAbleToChange = location.state?.isAbleToChange

  const {
    cancelOrder,
    createOrder,
    loading,
    selecteds,
    setInitialState,
    setLoading,
    setSelecteds,
    changeEmployeesInOrder,
    setUnselecteds,
    unselecteds,
  } = useOrder({ orderIdToEdit, operatorCardId })

  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      try {
        let query = orderIdToEdit
          ? `orderId=${orderIdToEdit}&operatorCardId=${operatorCardId}`
          : `operatorId=${TEMP_OPERATOR_ID}&operatorCardId=${operatorCardId}`
        const response = await api.get(`rh-bff/recharge/new/employees?${query}`)
        const processed = convertDataToComponentStructure(response.data)

        if (processed) {
          setUnselecteds(processed.filter((employee) => employee.selected === false))
          setSelecteds(processed.filter((employee) => employee.selected === true))
          setInitialState(processed.filter((employee) => employee.selected === true))
        }
      } catch (error) {
        return
      }
      setLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, orderIdToEdit])

  const convertDataToComponentStructure = (data) => {
    return data.reduce((employees, actualDepartment) => {
      let employeesFromDepartment = ''
      if (actualDepartment.employees)
        employeesFromDepartment = actualDepartment.employees.map((employee) => ({
          ...employee,
          departmentId: actualDepartment.department ? actualDepartment.department.id : 'without-department',
          departmentName: actualDepartment.department ? actualDepartment.department.name : 'Sem departamento',
        }))

      return [...employees, ...employeesFromDepartment]
    }, [])
  }

  const steps = [
    {
      name: 'Cartões',
      navigable: false,
    },
    {
      name: 'Colaboradores',
      navigable: false,
    },
    {
      name: 'Informações',
      navigable: false,
    },
    {
      name: 'Pagamento',
      navigable: false,
    },
  ]

  return (
    <>
      {orderIdToEdit && (
        <PageChangePrompt onlyConfirm>
          <p>Você não finalizou seu pedido.</p>
          <p>
            Seu pedido será salvo como <TextDecoration>Rascunho</TextDecoration> por uma semana e você poderá continuar
            de onde parou para finalizá-lo.
          </p>
        </PageChangePrompt>
      )}
      <Content>
        <Panel noPadding style={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>
          <PageHeader id='recharges_manualOrder_title'>Novo pedido manual</PageHeader>
          <StepsWrapper>
            {loading ? (
              <Spinner.Box>
                <Spinner />
              </Spinner.Box>
            ) : (
              <Steps currentStep={2} steps={steps} />
            )}
          </StepsWrapper>
        </Panel>
        {!loading && (
          <>
            <Container style={{ padding: 0, maxWidth: 'auto', backgroundColor: '#fff', borderRadius: '8px' }}>
              <Row nogutter>
                <Col>
                  <Operator>
                    <p>{operatorName}</p>
                    <small>{operatorCardName}</small>
                    {isAbleToChange ? (
                      <Link to='/recargas/novo_pedido_manual_cartoes'>
                        <p>Trocar cartão </p>
                        <ChangeIcon />
                      </Link>
                    ) : null}
                  </Operator>
                </Col>
              </Row>
              <Row nogutter>
                <Col>
                  <TransferList.Grouped
                    rightList={selecteds}
                    rightName='Colaboradores e setores selecionados'
                    setRight={setSelecteds}
                    leftList={unselecteds}
                    leftName='Selecionar colaboradores/setores'
                    setLeft={setUnselecteds}
                    displayName='name'
                    groupBy={(item) => item.departmentName || 'Sem departamento'}
                    blockGroups={[]}
                    loading={loading}
                  />
                </Col>
                <Col sm={12}>
                  <ButtonWrapper>
                    {orderIdToEdit ? (
                      <Button variant='outlined' onClick={cancelOrder} id='recharges_manualOrder_button_cancel'>
                        Cancelar
                      </Button>
                    ) : (
                      <Button
                        variant='outlined'
                        onClick={() => navigate('/recargas')}
                        id='recharges_manualOrder_button_return'
                      >
                        Cancelar
                      </Button>
                    )}

                    <Button
                      variant='contained'
                      disabled={selecteds.length === 0}
                      onClick={() => {
                        if (selecteds.length === 0) return
                        !orderIdToEdit && createOrder()
                        orderIdToEdit && changeEmployeesInOrder()
                      }}
                      id='recharges_manualOrder_button_confirm'
                    >
                      Confirmar
                    </Button>
                  </ButtonWrapper>
                </Col>
              </Row>
            </Container>
          </>
        )}
      </Content>
    </>
  )
}

export default RechargeEmployeesSelection
