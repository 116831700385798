import { useState } from 'react'

import { roundToMaxTwoDecimals } from 'utils/functionUtils'
import { ORDER_PAYMENT_TYPE } from 'utils/constants'
import api from '@/infra/api'
import { patchConfirmRechargeOrder, patchPayConfirmRechargeOrder } from 'services/rechargesServices'
import { useNotifications } from 'main/hooks/useNotifications'
import { IOrder } from './useRequestInfos/utils/infosData'
import { EMessageType } from '@/main/store/notifications'
import { errorHandler } from '@/main/utils/functions/errorHandler'
import { patchConfirmCardOrder, patchPayConfirmCardOrder } from '@/services/cardsServices'
import { useLocation, useNavigate } from 'react-router-dom'

interface IUseConfirmOrder {
  data: IOrder
}

export const useOrderOldIntegration = ({ data }: IUseConfirmOrder) => {
  const { push } = useNotifications()
  const navigate = useNavigate()
  const [success, setSuccess] = useState(false)
  const location = useLocation()
  const [loading, setLoading] = useState(false)

  const notAllowProjection = data?.ticketingBalanceValue > 0

  const type = () => {
    switch (location.pathname) {
      case '/cartoes/novo/segunda_confirmacao': {
        return {
          confirmOrder: patchConfirmCardOrder,
          confirmPay: patchPayConfirmCardOrder,
          orderType: 'cards',
          route: '/cartoes',
          title: 'Cartão 2ª via',
        }
      }
      case '/recargas/novo_pedido_importacao/confirmacao':
      case '/recargas/novo_pedido_manual/confirmacao': {
        return {
          confirmOrder: patchConfirmRechargeOrder,
          confirmPay: patchPayConfirmRechargeOrder,
          orderType: 'recharge',
          route: '/recargas',
          title: 'Recarga',
        }
      }
    }
  }

  const calculateRestToPay = ({ balanceUsage }: { balanceUsage: number }) => {
    const value = data?.totalBeforeBalanceUsage - balanceUsage
    if (value < 0) return data?.totalBeforeBalanceUsage
    return roundToMaxTwoDecimals(value)
  }

  const constructPaymentPayload = ({
    method,
    balanceUsage,
    useBalanceProjection,
    rate,
  }: {
    method: 'PIX' | 'SLIP' | 'TERM_BILL'
    balanceUsage: number
    useBalanceProjection: boolean
    rate: {
      interestRateDays: number
      interestRate: number
      nextDueDate: string
      rateValue: number
    }
  }) => {
    const payments = []
    const restToPay = calculateRestToPay({ balanceUsage })

    if (restToPay > 0 && method !== 'TERM_BILL')
      payments.push({
        paymentType: ORDER_PAYMENT_TYPE[method],
        amount: restToPay,
      })
    if (balanceUsage > 0)
      payments.push({
        paymentType: ORDER_PAYMENT_TYPE.ACCOUNT_BALANCE,
        amount: balanceUsage,
      })
    if (method === 'TERM_BILL') {
      payments.push({
        paymentType: ORDER_PAYMENT_TYPE[method],
        amount: restToPay + rate?.rateValue,
        ...rate,
      })
    }
    return {
      payments,
      useBalanceProjection,
    }
  }

  const service = type()

  const confirmOrder = async ({
    orderId,
    method,
    balanceUsage,
    useBalanceProjection,
    rate,
  }: {
    orderId: string
    method: 'PIX' | 'SLIP' | 'TERM_BILL'
    balanceUsage: number
    useBalanceProjection: boolean
    rate: {
      interestRateDays: number
      interestRate: number
      nextDueDate: string
      rateValue: number
    }
  }) => {
    if (success) return
    setLoading(true)
    const confirm = await service.confirmOrder(
      orderId,
      constructPaymentPayload({
        method,
        balanceUsage,
        useBalanceProjection: notAllowProjection ? false : useBalanceProjection,
        rate,
      }),
    )
    if (confirm.error || !confirm.response) {
      push(errorHandler({ error: confirm.error?.response?.data?.errors }))
      setLoading(false)
      return
    }
    if (confirm.response && !confirm.error) {
      const pay = await service.confirmPay(orderId)
      if (confirm.response && pay.response) {
        const pathname =
          ORDER_PAYMENT_TYPE[method] === ORDER_PAYMENT_TYPE.PIX
            ? '/recargas/pix'
            : '/recargas/novo_pedido_manual/pagamento'

        navigate(pathname, {
          state: {
            ...data,
            id: orderId,
            totalToPay: calculateRestToPay({ balanceUsage }) + (rate?.rateValue || 0),
            balanceUsage,
            useBalanceProjection: notAllowProjection ? false : useBalanceProjection,
            bypass: true,
            orderType: service.orderType,
            title: service.title,
          },
        })
        setSuccess(true)
        push({ content: 'Pedido realizado', type: EMessageType.Success })
      } else {
        pay.error && push(errorHandler({ error: pay.error?.response?.data?.errors }))
      }
    }
    setLoading(false)
  }

  const cancelOrder = async ({ orderId }: { orderId: string }) => {
    setLoading(true)
    try {
      await api.patch(`/orders-service/${orderId}/cancel`)
      navigate(service.route, {
        state: { bypass: true },
      })
    } catch (error) {
      push(errorHandler({ error: error?.response?.data?.errors }))
    }
    setLoading(false)
  }

  return {
    confirmOrder,
    cancelOrder,
    isLoading: loading,
  }
}
